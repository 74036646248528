import { createRouter, createWebHashHistory } from "vue-router";
import CSS21 from '../components/css21.vue'
const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      name: "道具商城",
      HeaderBgColor: "#A6E9FF",
    },
    component: CSS21,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
