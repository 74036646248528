import * as THREE from 'three';
import Stats from 'three/addons/libs/stats.module.js';
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { OutlineEffect } from 'three/addons/effects/OutlineEffect.js';
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { FBXLoader } from "three/addons/loaders/FBXLoader.js";
import { RectAreaLightHelper } from 'three/addons/helpers/RectAreaLightHelper.js';
import { RectAreaLightUniformsLib } from 'three/addons/lights/RectAreaLightUniformsLib.js';
import './gui.js'
import gsap from 'gsap'

  class CreateNPC{
    constructor(DOM,model=[],cameraPosition=[0,0,3]){
        this.scene = new THREE.Scene();
        // this.scene.fog = new THREE.Fog(0x000000, 2, 3);
        // this.scene.rotation.y = 0.5

        this.camera = new THREE.PerspectiveCamera(40,DOM.offsetWidth/DOM.offsetHeight,0.5,1000);
        this.camera.position.set(...cameraPosition);
        // this.camera.lookAt.set(0,0,0);
        // this.camera.rotation._y = Math.PI/2;
        // this.camera.updateProjectionMatrix()
        console.log(this.camera.rotation)
        this.scene.add(this.camera)

        this.renderer = new THREE.WebGLRenderer({antialias:true,alpha:true});
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(DOM.offsetWidth, DOM.offsetHeight);
        // this.setAnimationLoop(this.render())
        // 开启渲染器的投影功能
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // 默认的阴影类型

        // this.renderer.emissiveIntensity = 20.5;
        DOM.appendChild(this.renderer.domElement);

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.target.set(0, 0, 0);
        // this.controls.dampingFactor = 0.02
        this.controls.enableDamping = true
        this.controls.maxPolarAngle = Math.PI / 2.1;
        this.controls.minPolarAngle = 0;
        this.controls.minDistance = 7;
        this.controls.maxDistance = 25;
        // this.controls.autoRotate = true
        // console.log(this.controls)

        this.clock = new THREE.Clock();
        this.mixer = null;
        this.animations = [];
        this.options = null;
        this.expressions = null;

        this.cubeRenderTarget=null;
        this.cubeCamera=null

        this.OtherFns = []

        this.GUI = new dat.GUI({width: 200});

        this.light = new THREE.DirectionalLight( 0xffffff,2);
        const lightH = new THREE.DirectionalLightHelper(this.light);
        // this.scene.add(lightH)
        this.light.castShadow = true;

        // this.light.shadow.mapSize.width = 2048;
        // this.light.shadow.mapSize.height = 2048;

        // this.light.shadow.camera.near = 0;
        // this.light.shadow.camera.far = 1500;

        // this.light.shadow.camera.fov = 40;

        // this.light.shadow.bias = - 0.005;

        this.light.position.set( 10, 20, 10 );
        this.light.lookAt(0,0,0)
        this.light.castShadow = true;
        this.light.shadow.camera.top = 50;
        this.light.shadow.camera.bottom = - 50;
        this.light.shadow.camera.left = - 50;
        this.light.shadow.camera.right = 50;
        this.light.shadow.camera.near = 0;
        this.light.shadow.camera.far = 50;
        this.light.shadow.bias = 0.0001;
        this.light.shadow.mapSize.width = 4096*3;
		this.light.shadow.mapSize.height = 4096*3;

        
        this.scene.add(this.light )
        
        const material = new THREE.ShadowMaterial();
        material.opacity = 0.5;
        let plane = new THREE.Mesh(new THREE.PlaneGeometry(500,500), material)
        plane.receiveShadow = true
        plane.rotation.x = -Math.PI/2
        plane.position.y = -0.06
        this.GUI.add(material,'opacity',0,0.9).step(0.05).name('投影透明度')
        this.scene.add(plane)

        this.Cloths = [ ]

        this.config = {
            status:true
        }
        this.mesh= {};

        // this.GUI.add(this.light.shadow,'bias',0,1).step(0.01).name('光线比率')
        this.GUI.add(this.light.position,'x',-10,10).step(0.01).name('太阳X坐标')
        this.GUI.add(this.light.position,'y',5,20).step(0.01).name('太阳Y坐标')
        this.GUI.add(this.light.position,'z',-10,10).step(0.01).name('太阳Z坐标')
        
        let light1 = new THREE.DirectionalLight(0xffffff,5)
        light1.castShadow = true
        light1.position.set(-5,3,-4)
        // this.GUI.add(light1,'intensity',0,10).step(0.05).name('光线2强度')
        let lighthelper1 = new THREE.DirectionalLightHelper(light1)
        // this.scene.add(light1, lighthelper1)

        let light2 = new THREE.AmbientLight(0xffffff,5)
        this.scene.add(light2)
        this.GUI.add(light2,'intensity',0,20).step(0.1).name('环境光强度')
        this.GUI.add(this.light,'intensity',0,20).step(0.2).name('直线光强度')

        // this.AddCloth()

        this.Loader();
        model.forEach(i =>{
            this.AddNPC(i.name, i.position, true, i.NAME);
        })
        // this.AddHDR();
        this.AddStatus(DOM);
        // this.render();
        this.renderer.setAnimationLoop(this.render)

    }
    render = ()=>{
        // requestAnimationFrame(this.render); // 循环调用动画函数
        const delta = this.clock.getDelta();
        this.mixer ? this.mixer.update(delta) : "";
        this.controls.update();
        this.stats.update()
        // this.light ? this.light.update() : ''
        this.cubeCamera ? this.cubeCamera.update( this.renderer, this.scene ) : "";
        this.OtherFns.map(fn => fn(this.scene, this.camera))
        this.renderer.render(this.scene, this.camera);
        // this.effect.render( this.scene, this.camera );

    };

    EditConfig(stats=false){
        this.config.status = stats
    };

    AddStatus(DOM){
        this.stats = new Stats();
        DOM.appendChild(this.stats.dom)
    };

    AddOutLine(lineW=0.002, color=[0.3,0.3,0.3]){
        let parameters = {
            defaultThickness:lineW,//描边宽度
            defaultColor:color, // 描边颜色
            defaultAlpha:0.5,
            defaultKeepAlive:true
          }
        this.effect = new OutlineEffect( this.renderer, parameters );
    };

    Loader(){
        this.RGBELoader = new RGBELoader();

        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("./static/draco/gltf/");
        this.GLTFLoader = new GLTFLoader();
        this.GLTFLoader.setDRACOLoader(dracoLoader);

        this.FBXLoader = new FBXLoader();
    };

    AddHDR(url='https://lf-buckets.oss-cn-beijing.aliyuncs.com/023.hdr'){
        this.RGBELoader.loadAsync(url).then((tex) => {
            tex.mapping = THREE.EquirectangularReflectionMapping;
            this.scene.background = tex
            // this.scene.environment = tex;
        })
    };

    async AddNPC(name, position, ao=true, NAME){
        let nameArr = name.split('.')
        let type = nameArr[nameArr.length - 1]
        console.log(type)

        // this.scene.remove(this.model)

        if(['glb','gltf'].includes(type)){
            // await this.GLTFLoader.load('./static/glb/' + name, (gltf)=>{
                // https://lf-buckets.oss-cn-beijing.aliyuncs.com/%E5%BB%BA%E7%AD%91.glb
                await this.GLTFLoader.load('https://lf-buckets.oss-cn-beijing.aliyuncs.com/' + name, (gltf)=>{
                console.log(gltf.scene)
                this.animations = [...gltf.animations, ...this.animations];
                this.mixer = new THREE.AnimationMixer(gltf.scene)
                this.mesh = gltf.scene;
                this.mesh.scale.set(0.1,0.1,0.1)
                // this.mesh[NAME] = gltf.scene

                console.log('场景中的物体：',this.mesh)
                if(NAME === 'head'){
                    // gltf.scene.children[0].position.set(0,0,0)

                    this.mesh.main.children[0].add(gltf.scene.children[0].children[0])

                    let bozi = this.mesh.main.getObjectByName('bozi')
                    // gltf.scene.children[0].position.set(0,0,0)
                    bozi.add(gltf.scene.children[0].children[0])

                    let head = this.mesh.main.getObjectByName('head')
                    head.position.set(0,0,0)

                    this.GUI.add(head.position,'y',-1,5).step(0.1).name('头🦴骨骼位置')

                    // let root = this.mesh.main.getObjectByName("root")
                    // this.mesh.main.remove(root)

                    console.log('脖子骨骼:', bozi)
                    this.mixer = new THREE.AnimationMixer(this.mesh.main)
                    const helper = new THREE.SkeletonHelper( this.mesh.main );
                    this.scene.add( helper );
                    console.log('mesh', this.mesh)

                }else{
                    this.scene.add(gltf.scene)
                }

                gltf.scene.traverse((child)=>{
                    if(child.type === 'Mesh'){

                        child.castShadow = true;
                        child.receiveShadow = true;
                        console.log('添加阴影')

                        return

                        child.rowPosition = new THREE.Vector3().copy(child.position)
                        child.material.side = THREE.DoubleSide

                        child.fenjie = ()=>{
                            gsap.to(child.position,{
                              //   x:Math.pow(Math.abs(child.position.x),1.2),
                                // x:(Math.random() - 0.5)*50,
                                y:(Math.random() - 0.5)*10,
                                z:(Math.random() - 0.5)*10,
                                duration:1,
                                repeat:0
                            })
                          }
                          child.huifu = ()=>{
                            gsap.to(child.position,{
                                x:child.rowPosition.x,
                                z:child.rowPosition.z,
                                y:child.rowPosition.y,
                                duration:1,
                                repeat:0
                            })
                          }
                    }
                })

                // gsap.to(gltf.scene.position,{
                //     y:0.5,
                //     duration:1,
                //     repeat:-1,
                //     yoyo:true,
                //     // ease:'none',
                //     onUpdate:()=>{
                //       // lighthelper.update ()
                //     }
                // })
            })
        }else if(['fbx','FBX'].includes(type)){
            this.FBXLoader.load('./static/glb/' + name,model =>{
                this.scene.add(model)
                // this.animations = model.animations;
                // this.mixer = new THREE.AnimationMixer(model)
                model.scale.set(0.01,0.01,0.01)
                // this.mesh.add(model)  ;
                model.position.y = -0.5
                model.position.x = position.x
                
                // this.options = this.animations.map(i =>{
                    //     return {
                        //         label:i.name,
                        //         value:i
                        //     }
                        // })
                // this.mesh.traverse(child =>{
                //     // child.castShadow = true
                //     // child.receiveShadow = true
                //     console.log(model)
                //     // child.material = new THREE.MeshStandardMaterial({
                //     //     emissive:new THREE.Color(0x0f0f0f),
                //     //     emissiveIntensity: 0.0
                //     // })
                // })
            })
        }
    };

    AddFn(fn){
        // this.OtherFns.push(fn)
        this.OtherFns = [fn]
    }
}

export default CreateNPC
