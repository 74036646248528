<template>
    <div class="main">
      <div class="box container" ref="container"></div>
      <div class="box right" >
        <!-- <van-button @click="Boom()">{{  !isFenjie ? '爆炸效果' : '恢复' }}</van-button> -->
      </div>
    </div>

    <van-overlay :show="loading">
        <div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);">
            <van-loading type="spinner"/>
        </div>
    </van-overlay>
  </template>
  
  <script setup>
  import {onMounted,ref,watch} from 'vue'
  import CreateNPC from '@/utils/createNPC'
  import * as THREE from 'three';
  import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
  import gsap from 'gsap'
  let container = ref()

  onMounted( () => {
    init()
    document.addEventListener('click', onMouseMove)
    ThreeManager()
  });

  let loading = ref(true)
  const ThreeManager = ()=>{
    THREE.DefaultLoadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
      // console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
      let proge = (itemsLoaded/itemsTotal)*100
      if(proge===100){
        Change()
        loading.value = false
        NPC.value.camera.position.z = 70
        NPC.value.camera.position.y = 90
        gsap.to(NPC.value.camera.position,{
          z:10,
          y:5,
          repeat:0,
          duration:1,
        })
      }
    };
  }

  
  let NPC = ref()
  
  const init = ()=>{
    NPC.value = new CreateNPC(container.value,[
      {name: '建筑.glb',position:{x:0,y:0,z:0}, NAME:'main'},
    ],[0,0,32])
  }
  
let Body =ref([])
let css2DRender
const Change = ()=>{
    css2DRender = new CSS2DRenderer();
    css2DRender.setSize( window.innerWidth, window.innerHeight );
    css2DRender.domElement.style.position = 'absolute';
    css2DRender.domElement.style.top = '0px';
    css2DRender.domElement.style.pointerEvents = 'none'
    document.body.appendChild( css2DRender.domElement );

    NPC.value.AddFn(function(scene, camera){
      css2DRender.render(scene,camera)
      camera.rotation.z = Math.PI/2
      camera.updateProjectionMatrix()
      camera.lookAt(0,0,0)
    })

    // NPC.value.mesh.main.traverse(mesh =>{
    //   if(mesh.type === 'Mesh' && mesh.name.indexOf('零件') != -1){
    //     console.log(mesh.name)
    //     Body.value.push(mesh)
        
    //     if(mesh.material){
    //       mesh.materialClone = mesh.material.clone()
    //       mesh.material = mesh.materialClone
    //       mesh.css2d = true
    //       mesh.gsap = gsap.to(mesh.materialClone.color,{
    //         g:0,
    //         repeat:-1,
    //         duration:1,
    //         yoyo:true,
    //         onUpdate:()=>{
    //           mesh.materialClone.color.b = mesh.materialClone.color.g
    //         }
    //       })
    //     }
    //     mesh.gsap ? mesh.gsap.pause() : ''
    //     if(mesh.children.length){
    //       mesh.traverse(i =>{
    //         i.css2dParent = mesh
    //         if(i.material){
    //           i.materialClone = i.material.clone()
    //           i.material = i.materialClone
    //           i.gsap = gsap.to(i.materialClone.color,{
    //             g:0,
    //             repeat:-1,
    //             duration:1,
    //             yoyo:true,
    //             onUpdate:()=>{
    //               i.materialClone.color.b = i.materialClone.color.g
    //             }
    //           })
    //         }
    //         i.gsap ? i.gsap.pause() : ''
    //       })
    //     }
    //     const earthDiv = document.createElement( 'div' );
    //     earthDiv.className = 'css2label';
    //     earthDiv.innerHTML = `<div>
    //         <div>${mesh.name}</div>
    //         <div>XXX: xxxxxxx</div>
    //         <div>XXX: xxxxxxx</div>
    //         <div>XXX: xxxxxxx</div>
    //         <div>XXX: xxxxxxx</div>
    //       </div>` //.split('零件')[1];
        
    //     const Label = new CSS2DObject( earthDiv );
    //     Label.position.set( 0.3, 0, 0 );
    //     Label.center.set( 0, 1 );
    //     Label.visible = false
    //     Label.name = mesh.name.split('零件')[1]
    //     mesh.add( Label );
    //     Label.layers.set( 0 );
    //   }
    // })

  }

let isFenjie = ref(false)
const Boom = ()=>{
  console.log('爆炸')
  let obj = NPC.value.scene.getObjectByName('发动机')
  obj.traverse(child =>{
    if(child.type === 'Mesh'){
      !isFenjie.value ? child.fenjie() : child.huifu()
    }
  })
  isFenjie.value = !isFenjie.value
}

let mouseVector = new THREE.Vector2()
let raycaster = new THREE.Raycaster()
const onMouseMove = (event)=> {
    // 屏幕坐标
    const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
    const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;

    // 更新鼠标位置
    mouseVector.set(mouseX, mouseY);
    
    // 用于更新鼠标位置的射线
    raycaster.setFromCamera(mouseVector,NPC.value.camera);
    
    // 计算射线和物体的相交情况
    const intersects = raycaster.intersectObjects(Body.value);
    console.log('点击', intersects)
    
    if (intersects.length > 0) {
        // 第一个相交的物体
        const intersect = intersects[0];
        
        // 相交点的世界坐标
        const intersectPoint = intersect.point;
        
        // 在这里可以对相交的物体进行操作
        console.log(intersect.object.name, intersectPoint)


        // NPC.value.outlinePass.selectedObjects = intersect.object;

        // return
        Body.value.forEach(i=>{
          let label = i.getObjectByName(i.name.split('零件')[1])
          label.visible = false
          if(i.gsap){
            i.gsap.pause()
            i.materialClone.color.set(1,1,1)
          }else{
            i.traverse(n =>{
              if(n.gsap){
                n.gsap.pause()
                n.materialClone.color.set(1,1,1)
              }
            })
          }
        })
        if(intersect.object.css2d){
          intersect.object.materialClone.color.set(1,1,1)
          intersect.object.gsap.play()
          let label = intersect.object.getObjectByName(intersect.object.name.split('零件')[1])
          label.visible = true
        }
        if(intersect.object.css2dParent){
          intersect.object.css2dParent.traverse(i=> {
            if(i.materialClone ){
              i.materialClone.color.set(1,1,1)
              i.gsap.play()
              console.log('多个gsap，', i)
            }
          })
          let label = intersect.object.css2dParent.getObjectByName(intersect.object.css2dParent.name.split('零件')[1])
          label.visible = true
        }
        // 例如：intersect.object.material.color.set(0xff0000);
    }
}

window.onresize = function () {
      console.log('检测到宽度改变')
      let r = (container.value.offsetWidth) / container.value.offsetHeight
      console.log(r)
      if(r < 1){
        NPC.value.renderer.setSize( container.value.offsetHeight, container.value.offsetWidth );
        NPC.value.camera.aspect = 1/r
      }else{
        NPC.value.camera.aspect = r
        NPC.value.renderer.setSize((container.value.offsetWidth), container.value.offsetHeight);
      }
      // NPC.value.camera.aspect = (container.value.offsetWidth) / container.value.offsetHeight;
      NPC.value.camera.updateProjectionMatrix();
      // NPC.value.renderer.setSize((container.value.offsetWidth), container.value.offsetHeight);
      // NPC.value.effect.setSize( (container.value.offsetWidth), container.value.offsetHeight );
  };


  </script>
  
  <style lang="scss" scoped>
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .main{
    display: flex;
    justify-content: space-between;
    position: absolute;
    top:0;left:0;right:0;bottom:0;
    width:100vw;
    height:100vh;
    background-color: #000;
    .container{
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, #000, #000);
    }
    .right{
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      div{
        color: #fff;
      }
      .Btns{
        width: 200px;
        height: 80px;
        // background-color: #f53f3f;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        .img{
          width: 60px;
          height: 40px;
          border-radius: 6px;
          margin: 0 5px;
        }
        .img:nth-child(1){
          background: linear-gradient(to right, #0b3214, #164e2a);
        }
        .img:nth-child(2){
          background: linear-gradient(to right, #1c1d2b, #141732);
        }
        .img:nth-child(3){
          background: linear-gradient(to right, #5d5d5d, #797979);
        }
        .img:nth-child(4){
          background: linear-gradient(to right, #7c6936, #a38d4f);
        }
      }
    }
  }

  </style>
  