<template>
  <router-view></router-view>
</template>

<script setup>
import {onMounted,ref} from 'vue'

onMounted( () => {
});


</script>

<style lang="scss">
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background-color: black;
}
</style>
