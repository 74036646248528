import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'

import { Button, Circle, Swipe, SwipeItem, Toast, Overlay, Loading } from 'vant';
import 'vant/lib/index.css';

import './assets/index.css'

createApp(App)
.use(ElementPlus)
.use(router)
.use(Button)
.use(Circle)
.use(Swipe)
.use(SwipeItem)
.use(Toast)
.use(Overlay)
.use(Loading)
.mount('#app')
